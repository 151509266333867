import CryptoJS from 'crypto-js'

import { SECRET_KEY } from '../constants'

export function validateDocument(ci) {
  if (
    ci == '11111111' ||
    ci == '22222222' ||
    ci == '33333333' ||
    ci == '44444444' ||
    ci == '55555555' ||
    ci == '66666666' ||
    ci == '77777777' ||
    ci == '88888888' ||
    ci == '99999999'
  )
    return false

  ci = ci.replace(/\D/g, '')
  const dig = ci[ci.length - 1]
  ci = ci.replace(/[0-9]$/, '')
  return dig === getValidationNumber(ci) && ci.length === 7
}

function getValidationNumber(ci) {
  let a = 0
  let i = 0
  if (ci.length <= 6) {
    for (i = ci.length; i < 7; i++) {
      ci = `0${ci}`
    }
  }
  for (i = 0; i < 7; i++) {
    a += (parseInt('2987634'[i]) * parseInt(ci[i])) % 10
  }
  if (a % 10 === 0) {
    return '0'
  }

  return (10 - (a % 10)).toString()
}

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function validatePhone(phone) {
  const re = /^^((09[1-9](s?)([0-9]{3})(s?)([0-9]{3})))$/
  return re.test(phone)
}

export function formatAmount(amount) {
  return parseInt(amount)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export const encodeBase64 = (data) => {
  return Buffer.from(data).toString('base64')
}

export const decodeBase64 = (data) => {
  return Buffer.from(data, 'base64').toString('ascii')
}

export const getParamsFromBase64 = (url) => {
  // get query string from url (optional) or window
  let queryString = url ? url.split('?')[1] : window.location.search.slice(1)

  // we'll store the parameters here
  const obj = {}

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0]

    // split our query string into its component parts
    const arr = queryString.split('&')

    for (let i = 0; i < arr.length; i++) {
      // separate the keys and the values
      const a = arr[i].split('=')

      // set parameter name and value (use 'true' if empty)
      let paramName = a[0]
      let paramValue = typeof a[1] === 'undefined' ? true : a[1]

      // (optional) keep case consistent
      paramName = paramName.toLowerCase()
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase()

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        const key = paramName.replace(/\[(\d+)?\]/, '')
        if (!obj[key]) obj[key] = []

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          const index = /\[(\d+)\]/.exec(paramName)[1]
          obj[key][index] = paramValue
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue)
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]]
          obj[paramName].push(paramValue)
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue)
        }
      }
    }
  }

  return obj
}

export function getQueryVariable(variable) {
  const query = location.search.substring(1) || ''
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1])
    }
  }
}

export function getIpAddress(req) {
  let ip =
    req.headers['x-forwarded-for'] ||
    req.ip ||
    req.connection.remoteAddress ||
    req.socket.remoteAddress ||
    req.connection.socket.remoteAddress

  if (ip.substr(0, 7) == '::ffff:') {
    ip = ip.substr(7)
  }

  return ip
}

export function getSession(key) {
  if (typeof window !== 'undefined') {
    const stringValue = window.sessionStorage.getItem(key)
    if (stringValue !== null) {
      const value = JSON.parse(stringValue)
      const expirationDate = new Date(value.expirationDate)
      if (expirationDate > new Date()) {
        return value.value
      } else {
        window.sessionStorage.removeItem(key)
      }
    }
    return null
  }
}

export function setSession(key, value, expirationInMin = 1440) {
  if (typeof window !== 'undefined') {
    const expirationDate = new Date(
      new Date().getTime() + 60000 * expirationInMin
    )
    const newValue = {
      value: value,
      expirationDate: expirationDate.toISOString(),
    }
    window.sessionStorage.setItem(key, JSON.stringify(newValue))
  }
}

export function removeSession(key) {
  if (typeof window !== 'undefined') {
    window.sessionStorage.removeItem(key)
  }
}

export function encrypt(data) {
  return CryptoJS.AES.encrypt(data, SECRET_KEY).toString()
}
export function decrypt(data) {
  const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY)
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
}
