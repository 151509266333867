import { useEffect } from 'react'

const Loading = ({ message }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div
      className="d-flex align-items-center justify-content-center col-10 col-lg-5 col-md-6 col-sm-9 col-xl-4 container"
      style={{
        height: '65vh',
      }}
    >
      <div>
        <div className="d-flex justify-content-center">
          <div
            className="spinner-border text-action-1"
            role="status"
            style={{
              width: '2.5rem',
              height: '2.5rem',
            }}
          >
            <span className="sr-only"></span>
          </div>
        </div>
        <p
          className="text-dark-1 fw-bold text-center pt-5"
          style={{ fontSize: '1.2rem' }}
        >
          {message}
        </p>
      </div>
    </div>
  )
}

export default Loading
