import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import axios from 'axios'
import Cookies from 'js-cookie'

import Head from 'next/head'
import Image from 'next/image'
import * as Sentry from '@sentry/nextjs'

import ButtonForm from '../../Main/ButtonForm/ButtonForm'
import styles from './Homepage.module.css'
import {
  decodeBase64,
  getQueryVariable,
  setSession,
  validateDocument,
  validateEmail,
  validatePhone,
} from 'utils'
import Loading from '@/components/Loading'

const initialUserData = {
  ci: '',
  cell: '',
  email: '',
  acceptance: 'on',
}

const OfferForm = () => {
  const [userData, setUserData] = useState(initialUserData)
  const [isLoading, setIsloading] = useState(false)

  const router = useRouter()
  const { query } = router
  const { solicitud, documento, celular, email } = query

  useEffect(() => {
    if (documento || celular || email) {
      setUserData({
        ...userData,
        ci:
          documento && validateDocument(documento.toString())
            ? documento.toString()
            : '',
        cell: celular && validatePhone(celular) ? celular.toString() : '',
        email: email && validateEmail(email) ? email : '',
      })
    }

    if (solicitud) {
      const decodedSolicitud = decodeBase64(solicitud)
      const [documento, celular, email] = decodedSolicitud.split(',')

      if (validateDocument(documento) && validatePhone(celular)) {
        setUserData({
          ...userData,
          ci: documento,
          cell: celular,
          email: email && validateEmail(email) ? email : '',
        })
      }
    }
  }, [query])

  const sendApi1Data = async () => {
    setIsloading(true)

    try {
      const res = await axios.post(
        '/api/1',
        {
          request_info: {
            document: userData.ci,
            cellphone: userData.cell,
            email: userData.email,
            origin: 2,
            source: getQueryVariable('utm_source'),
            medium: getQueryVariable('utm_medium'),
            campaign: getQueryVariable('utm_campaign'),
          },
        },
        { timeout: 60000 }
      )

      setSession(
        'user_info',
        JSON.stringify({
          document: userData.ci,
          cellphone: userData.cell,
          email: userData.email,
          origin: 2,
        })
      )

      Cookies.set('have_user_info', JSON.stringify(true), {
        expires: 1,
      })

      if (res.data?.hasOffer == 'PENDIENTE') {
        setSession('finance_message', res.data?.ResMsg)

        router.replace('/solicitudPendiente')
      } else {
        router.replace('/datosExtra')
      }
    } catch (err) {
      Sentry.captureException(err)
      router.replace('/404')
    }
  }

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    })
  }

  const checkOffer = (e) => {
    e.preventDefault()
    if (
      userData.acceptance == 'on' &&
      validateDocument(userData.ci) &&
      validatePhone(userData.cell)
    ) {
      sendApi1Data()
    }
  }

  return (
    <div>
      <Head>
        <link
          href="https://fonts.googleapis.com/css2?family=Baloo+2:wght@100;200;300;400;500;600;700;800;900&family=Baloo+2:wght@100;200;300;400;500;600;700;800;900&subset=latin&display=swap"
          rel="stylesheet"
        />
      </Head>
      <section className="bg-bg-3 m-1">
        <div className="container">
          <div className="justify-content-center row float-xl-none mt-xl-8">
            <div className="col-6 py-lg-4 d-none d-md-block ">
              <Image
                src="/i/img-form.jpg"
                alt="img-form"
                width={546}
                height={675}
              />
            </div>
            {isLoading ? (
              <Loading message={'Estamos procesando tus datos...'} />
            ) : (
              <div className="py-6 py-lg-10 col-lg-5 col-md-6 col-sm-9 container col-xl-4">
                <div className="mb-9">
                  <h2 className="mb-4 " style={{ fontWeight: '700' }}>
                    Encontrá tus ofertas de préstamo.
                  </h2>
                  <p className="mb-8 form1para">
                    Completa el formulario con tus datos para obtener ofertas de
                    préstamos basadas en tu perfil crediticio.
                  </p>
                </div>
                <form onSubmit={checkOffer}>
                  <p
                    className={`text-dark-1 fw-bold fs-6 mb-2 ${styles.inpText} `}
                  >
                    Cédula de identidad
                  </p>

                  <div
                    className={`${styles.inpContainer} ${
                      userData.ci &&
                      !validateDocument(userData.ci) &&
                      `${styles.inpError}`
                    } mb-6`}
                  >
                    <span className={styles.inpIcon}>
                      <Image
                        src="/svgs/icon1.svg"
                        alt="icon1"
                        width={20}
                        height={20}
                      />
                    </span>
                    <span>
                      <input
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="Sin puntos ni guiones"
                        name="ci"
                        className={`${styles.inpForm1} data-hj-allow`}
                        required={true}
                        onChange={handleChange}
                        value={userData.ci}
                      />
                    </span>
                  </div>

                  <p
                    className={`text-dark-1 fw-bold fs-6 mb-2 ${styles.inpText} `}
                  >
                    Número de celular
                  </p>

                  <div
                    className={`${styles.inpContainer} ${
                      userData.cell &&
                      !validatePhone(userData.cell) &&
                      `${styles.inpError}`
                    } mb-6`}
                  >
                    <span className={styles.inpIcon}>
                      <Image
                        src="/svgs/icon2.svg"
                        alt="icon2"
                        width={20}
                        height={20}
                        className={styles.svgIcon}
                      />
                    </span>
                    <span>
                      <input
                        type="tel"
                        placeholder="09..."
                        name="cell"
                        className={`${styles.inpForm1} data-hj-allow`}
                        required={true}
                        onChange={handleChange}
                        value={userData.cell}
                      />
                    </span>
                  </div>

                  <p
                    className={`text-dark-1 fw-bold fs-6 mb-2  ${styles.inpText} `}
                  >
                    Correo electrónico
                  </p>

                  <div
                    className={`${styles.inpContainer} ${
                      userData.email &&
                      !validateEmail(userData.email) &&
                      `${styles.inpError}`
                    } mb-6`}
                  >
                    <span className={styles.inpIcon}>
                      <Image
                        src="/svgs/icon3.svg"
                        alt="icon3"
                        width={20}
                        height={20}
                      />
                    </span>
                    <span>
                      <input
                        type="email"
                        placeholder="ejemplo@correo.com"
                        name="email"
                        className={`${styles.inpForm1} data-hj-allow`}
                        onChange={handleChange}
                        value={userData.email}
                      />
                    </span>
                  </div>

                  <label
                    className={`form-check my-8 g-0 d-flex ${styles.container}`}
                  >
                    <input
                      type="checkbox"
                      onChange={handleChange}
                      name="acceptance"
                      required={true}
                      defaultChecked
                    />
                    <span className={styles.checkmark}></span>
                    <p className={styles.AceptoLos}>Acepto los</p>
                    <a
                      href="https://tuefectivo.uy/terminos-y-condiciones"
                      target={'_blank'}
                      // data-bs-toggle="modal"
                      // data-bs-target="#custom-id-ufmkchkw"
                      className="ms-1"
                      rel="noreferrer"
                    >
                      <div
                        className={`text-action-2 text-decoration-underline ${styles.AceptoLos} `}
                      >
                        términos y condiciones.
                      </div>
                    </a>
                    <div
                      className="modal fade"
                      id="custom-id-ufmkchkw"
                      tabIndex="-1"
                      aria-hidden="true"
                      style={{ display: 'none' }}
                    >
                      <div
                        className="
                      modal-dialog modal-dialog-centered modal-dialog-scrollable
                    "
                        style={{ fontWeight: 'bold' }}
                      >
                        <div
                          className="modal-content"
                          style={{ borderRadius: '1.875rem' }}
                        >
                          <div className="py-4 modal-header">
                            <h5
                              className="mb-0 text-dark-1"
                              style={{ fontWeight: 'bold' }}
                            >
                              Términos y condiciones
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="text-start modal-body">
                            <div>
                              <h5
                                className="mb-4 text-dark-1"
                                style={{ fontWeight: 'bold' }}
                              >
                                1. Aspectos generales
                              </h5>
                              <p>....</p>
                              <p>.....</p>
                            </div>
                          </div>
                          <div className="py-2 modal-footer">
                            <div
                              className="
                            g-0
                            align-items-center
                            w-100
                            row
                            justify-content-center
                          "
                            >
                              <div className="col-auto">
                                <button
                                  type="button"
                                  className={`btn btn-sm btn-outline-action-2 ${styles.modalButton}`}
                                  data-bs-dismiss="modal"
                                  style={{
                                    color: '#2f1893',
                                    borderColor: '#2f1893',
                                    padding: '0.5875rem 0.9375rem',
                                    borderRadius: '1.5625rem',
                                  }}
                                >
                                  Cerrar
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                  <ButtonForm title="Consultar prestamo" />
                </form>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  )
}

export default OfferForm
