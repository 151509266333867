import HomePage from '@/components/HomePage'
import MainLayout from '@/components/Layout/main-layout'

export default function Home() {
  return (
    <MainLayout title="tuefectivo – Encontrá tus ofertas de préstamo">
      <HomePage />
    </MainLayout>
  )
}
