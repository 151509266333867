/* eslint-disable react/display-name */
import React from 'react'
import styles from './ButtonForm.module.css'

const ButtonForm = React.forwardRef(({ clickFn, href, title }, ref) => {
  return (
    <button
      href={href}
      onClick={clickFn}
      ref={ref}
      className={`btn w-100 btn-outline-action-1 ${styles.FormBtn}`}
      type="submit"
    >
      {title}
    </button>
  )
})

export default ButtonForm
